import axios from 'axios'

const URL = process.env.NEXT_PUBLIC_API_URL

const API = {
  quote: `${URL}/quote`,
  checkout: `${URL}/checkout`,
}

const request = axios.create()

export {
  URL,
  API,
  request
}